import styled from 'styled-components';
import { SafetyMetricTile } from './SafetyMetricTile';
import { useSafetyContext } from '../../../../../contexts/safetyContext';
import { useNavigation } from '../../../../../hooks/useNavigation';
import { useCallback, useMemo } from 'react';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { SafetyMetricSlug, safetyMetricMetaData } from '../../../../../api/safety';

export const SafetyMetrics = () => {
  const { navigateToSafety, navigateToSafetyMetricDetail, navigateToSafetyMetricDetailFloor } =
    useNavigation();
  const { state: safetyState } = useSafetyContext();
  const { state: buildingState } = useBuildingContext();

  const trackersSlugs = useMemo(() => {
    return safetyState.trackers.map((tracker: any) => tracker.type.slug);
  }, [safetyState.trackers]);

  const pertinentMetricMetaData = useMemo(() => {
    const hardcodedSlugs = ['lighting', 'ppe_compliance', 'holes', 'fire_extinguisher'];
    const allowedSlugs = [...hardcodedSlugs, ...trackersSlugs];
    return Object.entries(safetyMetricMetaData).filter(entry => {
      return allowedSlugs.includes(entry[0]);
    });
  }, [trackersSlugs]);

  const generateFloorCodeParam = useCallback(() => {
    if (buildingState.floorId) {
      return buildingState.floorId;
    }

    const initialFloorCode = buildingState.projectData.floors[0].floor_code;
    const l1InFloors = !!buildingState.projectData.floors.find(
      (floor: any) => floor.floor_code === 'L1'
    );

    return l1InFloors ? 'L1' : initialFloorCode;
  }, [buildingState.floorId, buildingState.projectData.floors]);

  const onClickTile = useCallback(
    (slug: string, display_mode: string) => {
      if (display_mode === 'gallery') {
        navigateToSafety(slug);
      } else if (display_mode === 'floor_plan') {
        const floorCode = generateFloorCodeParam();

        navigateToSafety(slug, floorCode);
      }
    },
    [generateFloorCodeParam, navigateToSafety]
  );

  const onClickExpand = useCallback(
    (slug: string, display_mode: string) => {
      if (display_mode === 'gallery') {
        navigateToSafetyMetricDetail(slug);
      } else if (display_mode === 'floor_plan') {
        const floorCode = generateFloorCodeParam();

        navigateToSafetyMetricDetailFloor(floorCode, slug);
      }
    },
    [generateFloorCodeParam, navigateToSafetyMetricDetail, navigateToSafetyMetricDetailFloor]
  );

  return (
    <SafetyMetricsContainer>
      {pertinentMetricMetaData.map((entry, i, arr) => {
        const slug = entry[0] as SafetyMetricSlug;
        const metric = { ...entry[1] };

        return (
          <SafetyMetricTile
            key={metric.metric_tile_name}
            metric={metric}
            slug={slug}
            index={i}
            totalTilesCount={arr.length}
            selected={safetyState.selectedMetricCategory === slug}
            onClickTile={() => onClickTile(slug, metric.display_mode)}
            onClickExpand={() => onClickExpand(slug, metric.display_mode)}
          />
        );
      })}
    </SafetyMetricsContainer>
  );
};

const SafetyMetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
