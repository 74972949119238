import { useQuery } from "react-query";
import {
  fetchFloors,
  fetchPointImages,
  fetchPoints,
  fetchProject,
  fetchFloor,
  fetchImage,
  fetchPointImage,
} from "../../../../api/buildingFetches";
import { Point } from "../../../../api/types";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { PATH_STRINGS } from "../../../../hooks/useGeneratedPaths";
import { listProjects } from "../../../../api/projects";
import { useUserContext } from "../../../../contexts/userContext";

const NEVER_EXCLUDE_REGION_IMAGES =
  process.env.REACT_APP_NEVER_EXCLUDE_REGION_IMAGES === "1";

let urlCreator = window.URL || window.webkitURL;

export const useListProjectsQuery = (status?: string) => {
  const { state: userState } = useUserContext();

  return useQuery(["projects"], async () => {
    return await listProjects(userState.public_id, status);
  });
};

export const useListProjectFloorsQuery = (projectId: string) => {
  return useQuery(
    ["floors", projectId],
    async () => {
      return await fetchFloors(projectId);
    },
    { enabled: !!projectId },
  );
};

export const useFloorImages = (
  onSuccess: (data: any) => void,
  activeOnly: boolean = false,
  isReviewed?: boolean,
  isApproved?: boolean,
  excludeRegionPictures?: boolean,
) => {
  const [floorImageMap, setFloorImageMap] = useState<any>({});
  const buildingState = useBuildingContext().state;

  return useQuery(
    [
      activeOnly,
      buildingState.projectId,
      buildingState.floorId,
      excludeRegionPictures,
    ],
    async () => {
      let floor = await fetchFloor(
        buildingState.projectId,
        buildingState.floorId,
      );
      // @ts-ignore
      if (floorImageMap[floor.floor_code]) {
        // @ts-ignore
        floor.imageUrl = floorImageMap[floor.floor_code];
      } else {
        floor.img = await fetchImage(floor.latest_floor_plan.web_image_url);
        floor.imageUrl = urlCreator.createObjectURL(floor.img);
        setFloorImageMap((prevState: any) => {
          // @ts-ignore
          prevState[floor.floor_code] = floor.imageUrl;
          return prevState;
        });
      }

      const floorImagesParams = {
        is_reviewed: isReviewed,
        is_approved: isApproved,
        exclude_region_images: NEVER_EXCLUDE_REGION_IMAGES
          ? false
          : excludeRegionPictures,
      };

      const floorImages = await fetchPointImages(
        buildingState.projectId,
        floor.floor_code,
        floorImagesParams,
      );
      floor.images = floorImages;
      const floorPoints = await fetchPoints(
        buildingState.projectId,
        floor.floor_code,
        activeOnly,
      );
      floor.points = floorPoints.map((point: Point) => {
        let pointImages = floorImages.filter(
          (image: any) => image.sub_point_id === point.point_id,
        );
        pointImages = [...pointImages].sort((a: any, b: any) =>
          new Date(a.taken_on).getTime() < new Date(b.taken_on).getTime()
            ? 1
            : -1,
        );
        return {
          ...point,
          images: pointImages,
        };
      });
      return floor;
    },
    {
      retry: 3,
      enabled: !!buildingState.floorId,
      onSuccess: (data) => onSuccess(data),
    },
  );
};

export const useBuildingQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    ["building"],
    async () => {
      const building = await fetchProject(buildingState.projectId);
      building.floors = await fetchFloors(buildingState.projectId);
      return building;
    },
    {
      enabled: !!buildingState.projectId,
      onSuccess: (data) => onSuccess({ projectData: data }),
    },
  );
};

export const findClosestDate = (
  searchDate: Date,
  dateA: string | Date,
  dateB: string | Date,
) => {
  var distancea = Math.abs(searchDate.getTime() - new Date(dateA).getTime());
  var distanceb = Math.abs(searchDate.getTime() - new Date(dateB).getTime());
  return distancea - distanceb;
};

export const useImagesQuery = (
  dateRange: any,
  currentImage: any,
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
) => {
  const buildingState = useBuildingContext().state;
  const progressFloorMatch = useRouteMatch(PATH_STRINGS.progressFloor);
  const safetyFloorMatch = useRouteMatch([
    PATH_STRINGS.safetyMetricDetailFloor,
    PATH_STRINGS.safetyMetricDetailFloorPoint,
  ]);

  const filterDateRange = (point: Point) => {
    if (buildingState.dateRange) {
      return (
        new Date(point.taken_on) >= buildingState.dateRange.startDate &&
        new Date(point.taken_on) <= buildingState.dateRange.endDate
      );
    }

    return true;
  };

  return useQuery(
    [
      buildingState.projectId,
      buildingState.floorData.floor_code,
      buildingState.pointId,
      buildingState.dateRange,
    ],
    async () => {
      // Find image for current point
      // filter & sort by clicked_on so we get the most recent one.
      let found = buildingState.floorData.images
        // eslint-disable-next-line
        .filter((image: any) => buildingState.pointId == image.sub_point_id)
        .sort(
          (a: any, b: any) =>
            new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime(),
        );

      if (buildingState.dateRange) {
        found = found.filter(filterDateRange);
      }

      const dateParam = new URLSearchParams(window.location.search).get("date");

      if (dateParam) {
        let searchDate = new Date(String(dateParam));

        if (progressFloorMatch || safetyFloorMatch) {
          const searchDateMax = new Date(searchDate);
          searchDateMax.setHours(23, 59, 59, 999);

          const beforeSearchDate = found.filter(
            (point: Point) => new Date(point.taken_on) <= searchDateMax,
          );

          if (beforeSearchDate.length > 0) {
            found = beforeSearchDate;
          }
        }

        found.sort((pointA: Point, pointB: Point) =>
          findClosestDate(searchDate, pointA.taken_on, pointB.taken_on),
        );
      }

      found = found[0];

      if (!found) {
        return null;
      }

      // TODO: image metadata from backend was supposed to contain dimensions already

      // Fetch image metadata from Nextera API
      const viewpointsImage = await fetchPointImage(
        buildingState.projectId,
        buildingState.floorData.floor_code,
        found.id,
      );

      // Download the actual image file
      const imageBlob = await fetchImage(viewpointsImage.processed_image_url);

      // Load the image to obtain other data like width and height
      const imageObjectURL = urlCreator.createObjectURL(imageBlob);
      const image = new Image();
      image.src = imageObjectURL;
      await new Promise((resolve) => {
        image.onload = resolve;
      });

      // Revoke previous blob URL if any
      if (currentImage?.image) {
        urlCreator.revokeObjectURL(currentImage.image);
      }

      // Return image and metadata with dimensions
      return {
        image: urlCreator.createObjectURL(imageBlob),
        processed_image: viewpointsImage.processed_image_object,
        data: found,
        dimensions: {
          width: image.width,
          height: image.height,
        },
      };
    },
    {
      retry: 3,
      onSuccess: (data) => onSuccess(data),
      enabled:
        !!buildingState.pointId &&
        Object.entries(buildingState.floorData).length !== 0,
      onError: (err) => onError(null),
    },
  );
};
