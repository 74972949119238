import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { useBuildingContext } from './buildingContext';
import { listProjectTrackers, ProjectTracker } from '../api/trackers';
import { listTrackerJobTypes } from '../api/jobs';

let initialObservationDate = new Date();
const searchParams = new URLSearchParams(window.location.search);
const searchParamsDateString = searchParams.get('date');

if (!!searchParamsDateString) {
  initialObservationDate = new Date(searchParamsDateString);

  if (isNaN(initialObservationDate.getTime())) {
    initialObservationDate = new Date();
  }
}

const initialState = {
  trackers: [],
  observationDate: initialObservationDate,
  showPoints: false,
  activeTracker: null,
};

const ProgressContext = createContext<any>(null);

const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
const UPDATE_ACTIVE_TRACKER = 'UPDATE_ACTIVE_TRACKER';

const progressReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...state,
        ...action.payload.item,
      };
    case UPDATE_ACTIVE_TRACKER:
      return {
        ...state,
        activeTracker: action.payload.item,
      };
    default:
      return state;
  }
};

export const ProgressContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(progressReducer, initialState);
  const { state: BuildingState } = useBuildingContext();

  const addTypes = useCallback(async (tracker: ProjectTracker) => {
    let types = await listTrackerJobTypes(BuildingState.projectId, tracker.type.name.toLowerCase());
    return { ...tracker, types: types.data };
  }, [BuildingState.projectId]);

  const fetchTrackers = useCallback(async () => {
    let data = await listProjectTrackers(BuildingState.projectId);
    let trackers = await Promise.all(data.data.map((tracker: ProjectTracker) => addTypes(tracker)));
    dispatch({
      type: 'UPDATE_PROGRESS',
      payload: { item: { trackers: trackers } },
    });
    if (!state.activeTracker) {
      dispatch({
        type: 'UPDATE_ACTIVE_TRACKER',
        payload: { item: trackers[0].type.name },
      })
    }
  }, [BuildingState.projectId, addTypes, state.activeTracker]);

  useEffect(() => {
    fetchTrackers();
  }, [fetchTrackers]);

  return <ProgressContext.Provider value={[state, dispatch]}>{children}</ProgressContext.Provider>;
};

export const useProgressContext = () => {
  const [state, dispatch] = useContext(ProgressContext);

  const updateProgress = useCallback((item: any) => {
    dispatch({
      type: UPDATE_PROGRESS,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updateActiveTracker = useCallback((item: any) => {
    dispatch({
      type: UPDATE_ACTIVE_TRACKER,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  return {
    updateProgress,
    updateActiveTracker,
    state,
  };
};
