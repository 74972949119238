import axios from "axios";
import { useCallback, useEffect } from "react";
import { NewRFI } from "../components/common/Procore/components/NewRFIForm";
import { useImageViewerContext } from "../components/views/image_viewer/imageViewerContext";
import { useBuildingContext } from "../contexts/buildingContext";
import { useTagContext } from "../contexts/tagContext";
import { useUserContext } from "../contexts/userContext";
import { PATH_STRINGS } from "../hooks/useGeneratedPaths";
import { TagRFI } from "./types";

const SERVICES_API = process.env.REACT_APP_SERVICES_API;

const nexteraApiBaseUrl = process.env.REACT_APP_NEXTERA_API;
const servicesApiBaseUrl = process.env.REACT_APP_SERVICES_API;

export const procoreClientId = process.env.REACT_APP_PROCORE_CLIENT_ID;
export const redirectUri = process.env.REACT_APP_PROCORE_REDIRECT_URI;
export const procoreLoginUrl = `${process.env.REACT_APP_PROCORE_LOGIN_URL}?response_type=code&client_id=${procoreClientId}&redirect_uri=${redirectUri}`;

let reVerifyTokenPromise: Promise<any> | null = null;

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (r) => r,
  (error) => {
    if (error.config && error.response && error.response.status === 401) {
      if (!reVerifyTokenPromise) {
        reVerifyTokenPromise = verifyProcoreToken().then((token) => {
          reVerifyTokenPromise = null;
          return token;
        });
      }

      return reVerifyTokenPromise.then((token) => {
        return axiosInstance.request(error.config);
      });
    }
    return Promise.reject(error);
  },
);

interface ProcoreUser {
  id: number;
  login: string;
  name: string;
}

interface ProcoreIndividual extends ProcoreUser {
  locale: string | null;
}

interface ProcoreAssignee extends ProcoreIndividual {
  response_required: boolean;
}

interface Vendor {
  id: number;
  name: string;
}

interface SpecSections {
  id: number;
  number: string;
  description: string;
  label: string;
  current_revision_id: number;
}

interface Location {
  id: number;
  name: string;
  node_name: string;
  parent_id: number;
  created_at: string;
  updated_at: string;
  code: string;
}

interface ProcoreLocation {
  id: number;
  name: string;
  node_name: string;
}

interface ProcoreSpecSection {
  description: string;
  id: number;
  label: string;
}

interface ProcoreContractor {
  id: number;
  name: string;
}

interface ProcoreQuestion {
  created_by: string;
  id: number;
  plain_text_body: string;
}

interface ExistingRFI {
  id: number;
  assignees: ProcoreAssignee[];
  number: number;
  subject: string;
  received_from: ProcoreUser | null;
  rfi_manager: { name: string };
  responsible_contractor: ProcoreContractor | null;
  distribution_list: ProcoreIndividual[] | null;
  drawing_number: string | null;
  specification_section: ProcoreSpecSection | null;
  location: ProcoreLocation | null;
  questions: ProcoreQuestion[];
  link: string;
  status: "draft" | "open" | "closed";
}

interface ProcorePresignedPost {
  uuid: string;
  url: string;
  fields: Record<string, string>;
}

interface ProcoreImageCategory {
  id: number;
  name: string;
}

type ProcoreUserPermissionName = "rfi" | "images";

export interface ProcoreUserPermission {
  available_for_user: boolean;
  can_create: boolean | null;
  name: ProcoreUserPermissionName;
}

const uploadToProcorePresignedPost = async (
  postUrlData: ProcorePresignedPost,
  blob: Blob,
  fileName: string,
) => {
  const form = new FormData();
  Object.entries(postUrlData.fields).forEach(([field, value]) => {
    form.append(field, value);
  });

  const file = new File([blob], fileName);

  form.append("file", file);

  const uploadResponse = await axios.post(postUrlData.url, form, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "",
    },
  });

  const uploadResponseData = await uploadResponse.data;

  return uploadResponseData.data;
};

export const useProcoreCalls = () => {
  const { state: buildingState } = useBuildingContext();
  const projectData = buildingState.projectData;
  const { procore_company_id, procore_project_id } = projectData;

  useEffect(() => {
    const companyIdInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          "X-PROCORE-COMPANY-ID": procore_company_id,
        };
        return config;
      },
    );

    return () => {
      axiosInstance.interceptors.request.eject(companyIdInterceptor);
    };
  }, [procore_company_id]);

  const showProcorePermissionManifest = useCallback(async (): Promise<
    ProcoreUserPermission[]
  > => {
    // TODO: don't even try if there is no procore project id
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/show_permission_manifest`,
    );
    const response = await res.data;
    return response.data.tools;
  }, [procore_project_id]);

  const listRFIs = useCallback(async (): Promise<ExistingRFI[]> => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/list_rfis?filters[status]=open`,
    );
    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const createRFI = useCallback(
    async (rfi: NewRFI) => {
      const res = await axiosInstance.post(
        `${nexteraApiBaseUrl}/procore/${procore_project_id}/create_rfi`,
        { rfi: rfi },
      );
      const response = await res.data;
      return response.data;
    },
    [procore_project_id],
  );

  const fetchRFI = useCallback(
    async (rfiId: number): Promise<ExistingRFI> => {
      const res = await axiosInstance.get(
        `${nexteraApiBaseUrl}/procore/${procore_project_id}/show_rfi/${rfiId}`,
      );
      const response = await res.data;
      return response.data;
    },
    [procore_project_id],
  );

  const getPotentialRFIManagers = useCallback(async (): Promise<
    ProcoreIndividual[]
  > => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/potential_rfi_managers`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const getPotentialRFIAssignees = useCallback(async (): Promise<
    ProcoreIndividual[]
  > => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/potential_rfi_assignees`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const getProcoreProjectVendors = useCallback(async (): Promise<Vendor[]> => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/vendors`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const getProcoreSpecSections = useCallback(async (): Promise<
    SpecSections[]
  > => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/specification_sections`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const getProcoreProjectLocations = useCallback(async (): Promise<
    Location[]
  > => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/locations`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const getPotentialRFIDistributionMembers = useCallback(async (): Promise<
    ProcoreIndividual[]
  > => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/potential_rfi_distribution_members`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const getPotentialRFIReceivedFroms = useCallback(async (): Promise<
    ProcoreIndividual[]
  > => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/potential_rfi_received_froms`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  const createProcoreUpload = useCallback(
    async (fileName: string): Promise<ProcorePresignedPost> => {
      const res = await axiosInstance.post(
        `${nexteraApiBaseUrl}/procore/${procore_project_id}/create_project_upload`,
        {
          response_filename: fileName,
        },
      );

      const response = await res.data;
      return response.data;
    },
    [procore_project_id],
  );

  const createImageInProcore = useCallback(
    async (
      uuid: string,
      fileName: string,
      imageCategoryId: number | undefined,
    ) => {
      const res = await axiosInstance.post(
        `${nexteraApiBaseUrl}/procore/${procore_project_id}/create_image`,
        {
          upload_uuid: uuid,
          image_name: fileName,
          image: {
            log_date: new Date().toISOString(),
            image_category_id: imageCategoryId,
          },
        },
      );

      const response = await res.data;
      return response.data;
    },
    [procore_project_id],
  );

  const uploadImageToProcore = useCallback(
    async (
      blob: Blob,
      fileName: string,
      imageCategoryId: number | undefined,
    ) => {
      const postUrlData = await createProcoreUpload(fileName);

      await uploadToProcorePresignedPost(postUrlData, blob, fileName);

      return await createImageInProcore(
        postUrlData.uuid,
        fileName,
        imageCategoryId,
      );
    },
    [createImageInProcore, createProcoreUpload],
  );

  const getImageCategories = useCallback(async (): Promise<
    ProcoreImageCategory[]
  > => {
    const res = await axiosInstance.get(
      `${nexteraApiBaseUrl}/procore/${procore_project_id}/get_image_categories`,
    );

    const response = await res.data;
    return response.data;
  }, [procore_project_id]);

  return {
    showProcorePermissionManifest,
    listRFIs,
    createRFI,
    fetchRFI,
    getPotentialRFIManagers,
    getPotentialRFIAssignees,
    getProcoreProjectVendors,
    getProcoreSpecSections,
    getProcoreProjectLocations,
    getPotentialRFIDistributionMembers,
    getPotentialRFIReceivedFroms,
    uploadImageToProcore,
    getImageCategories,
  };
};

export const verifyProcoreToken = async (): Promise<ProcoreUser> => {
  const res = await axios.post(`${nexteraApiBaseUrl}/procore/verify_token`, {
    redirect_uri: redirectUri,
  });

  const response = await res.data;

  return response.data;
};

interface AccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  created_at: number;
}

export const getAccessToken = async (
  authorizationCode: string,
): Promise<AccessTokenResponse> => {
  const res = await axios.post(
    `${nexteraApiBaseUrl}/procore/get_access_token`,
    {
      code: authorizationCode,
      redirect_uri: redirectUri,
    },
  );
  const response = await res.data;
  return response.data;
};

interface ProcoreCompany {
  id: number;
  is_active: boolean;
  name: string;
  pcn_business_experience: any;
}

export const getUserProcoreCompanies = async (): Promise<ProcoreCompany[]> => {
  const res = await axiosInstance.get(
    `${nexteraApiBaseUrl}/procore/get_companies`,
  );
  const response = await res.data;
  return response.data;
};

interface ProcoreProject {
  id: number;
  name: string;
}

export const getProcoreCompanyProjects = async (
  companyId: string | number,
): Promise<ProcoreProject[]> => {
  const res = await axiosInstance.get(
    `${nexteraApiBaseUrl}/procore/get_company_projects`,
    {
      headers: {
        "X-PROCORE-COMPANY-ID": companyId,
      },
    },
  );
  const response = await res.data;
  return response.data;
};

export const useCreateRFITag = () => {
  const { state: userState } = useUserContext();
  const { state: buildingState } = useBuildingContext();
  const { state: tagState } = useTagContext();

  return useCallback(
    async (rfiId: number | string) => {
      const res = await axios.post(`${servicesApiBaseUrl}/tags/`, {
        creator: userState.public_id,
        pitch: tagState.tags.current.pitch,
        yaw: tagState.tags.current.yaw,
        project_id: buildingState.projectId,
        point_id: buildingState.pointId,
        floor_id: buildingState.floorId,
        image_id: buildingState.imageData.data.id,
        type: "RFI",
        rfi_id: rfiId,
        last_edited_by: userState.public_id,
      });

      const response = await res.data;
      return response.data;
    },
    [
      buildingState.floorId,
      buildingState.imageData.data.id,
      buildingState.pointId,
      buildingState.projectId,
      tagState.tags,
      userState.public_id,
    ],
  );
};

export const updateRFITag = async (
  rfiId: number | string,
  data: Partial<TagRFI>,
): Promise<TagRFI[]> => {
  const res = await axios.patch(
    `${SERVICES_API}/tags/rfis/update_rfi_status/${rfiId}/`,
    data,
  );
  return await res.data;
};
