import styled from 'styled-components';
import { useSafetyContext } from '../../../../contexts/safetyContext';
import { SafetyMetricSlug, holesTypeCodes, safetyMetricMetaData } from '../../../../api/safety';
import { SafetyFireExtinguisherFloorPlanView } from '../SafetyFloorPlanView/SafetyFireExtinguisherFloorPlanView/SafetyFireExtinguisherFloorPlanView';
import { SafetyGuardrailsFloorPlanView } from '../SafetyFloorPlanView/SafetyGuardrailsFloorPlanView/SafetyGuardrailsFloorPlanView';
import { SafetyClutterFloorPlanView } from '../SafetyFloorPlanView/SafetyClutterFloorPlanView/SafetyClutterFloorPlanView';
import { SafetyLightingFloorPlanView } from '../SafetyFloorPlanView/SafetyLightingFloorPlanView/SafetyLightingFloorPlanView';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { ObservationTable } from '../SafetyOverview/ObservationTable/ObservationTable';
import { CompositeSafetyIndex } from '../SafetyOverview/CompositeSafetyIndex/CompositeSafetyIndex';
import { SafetyMetrics } from '../SafetyOverview/SafetyMetrics/SafetyMetrics';
import {
  useFetchProjectTrackerMetricSummaryQuery,
  useFetchFireExtinguisherViewpointDistanceSummaryQuery,
  useFetchGuardrailObservationSummaryQuery,
  useFetchLightingMetricSummaryQuery,
  useFetchObservationStatsQuery,
  useFetchPPEMetricSummaryQuery,
} from '../hooks/safetyQueries';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { ObservationView } from '../SafetyOverview/ObservationView/ObservationView';
import axios from 'axios';
import { listObservations } from '../../../../api/observations';
import { CompositeSafetyIndexProvider } from '../../../../contexts/compositeSafetyIndexContext';
import { useLocation } from 'react-router-dom';
import { SafetyWetSurfaceFloorPlanView } from '../SafetyFloorPlanView/SafetyWetSurfaceFloorPlanView/SafetyWetSurfaceFloorPlanView';
import { ViewerPosition } from '../../image_viewer/types';

const urlCreator = window.URL || window.webkitURL;

let observationCancelTokenSource = axios.CancelToken.source();

export const SafetyHomePage = () => {
  const { state: BuildingState } = useBuildingContext();
  const { state: safetyState, updateSafety, addSafetyObservations } = useSafetyContext();

  const { projectId } = BuildingState;

  const trackersSlugs = useMemo(() => {
    return safetyState.trackers.map((tracker: any) => tracker.type.slug);
  }, [safetyState.trackers]);

  const { activeObservation, observations } = safetyState;

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const metricSummaryLoading =
    safetyState.metricSummaryLoadingStatus[safetyState.selectedMetricCategory as SafetyMetricSlug];

  const [observationsLoading, setObservationsLoading] = useState<boolean>(true);
  const [viewerPosition, setViewerPosition] = useState<ViewerPosition>({
    yaw: 0,
    pitch: 0,
    hfov: 120,
  });
  const [observationFetchNextPage, setObservationFetchNextPage] = useState<string | null>(null);

  const { data: lightingMetricSummary, isLoading: lightingMetricSummaryLoading } =
    useFetchLightingMetricSummaryQuery(projectId);
  const { data: ppeMetricSummary, isLoading: ppeMetricSummaryLoading } =
    useFetchPPEMetricSummaryQuery(projectId);
  const { data: guardrailObservationSummary, isLoading: guardrailObservationSummaryLoading } =
    useFetchGuardrailObservationSummaryQuery(projectId);
  const { data: holesObservationSummary, isLoading: holesObservationSummaryLoading } =
    useFetchObservationStatsQuery(projectId, { type_codes: holesTypeCodes.join(',') });
  const { data: clutterMetricSummary, isLoading: clutterMetricSummaryLoading } =
    useFetchProjectTrackerMetricSummaryQuery(projectId, 'Clutter');
  const { data: wetSurfaceMetricSummary, isLoading: wetSurfaceMetricSummaryLoading } =
    useFetchProjectTrackerMetricSummaryQuery(projectId, 'Wet Surface');
  const {
    data: fireExtinguisherViewpointDistanceSummary,
    isLoading: fireExtinguisherViewpointDistanceSummaryLoading,
  } = useFetchFireExtinguisherViewpointDistanceSummaryQuery(projectId);
  const { data: snowIceMetricSummary, isLoading: snowIceMetricSummaryLoading } =
    useFetchProjectTrackerMetricSummaryQuery(projectId, 'Snow and Ice');

  const selectedMetricCategorySlug = safetyState.selectedMetricCategory;

  const selectedMetricCategory = useMemo(() => {
    return safetyMetricMetaData[selectedMetricCategorySlug as SafetyMetricSlug];
  }, [selectedMetricCategorySlug]);

  const selectedDisplayMode = selectedMetricCategory.display_mode;

  const loadGalleryObservations = useCallback(
    async (nextPage?: string | null) => {
      try {
        const observationDate = safetyState.observationDate;
        const observationDateMidnight = new Date(
          new Date(observationDate).setHours(18, 59, 59, 999)
        );
        const weekBeforeObservationDate = new Date(observationDate);
        weekBeforeObservationDate.setDate(weekBeforeObservationDate.getDate() - 7);
        weekBeforeObservationDate.setHours(0, 0, 0, 0);

        let filters: any = {
          end: `${observationDateMidnight.toISOString().split('T')[0]}T23:59:59Z`,
          limit: 10,
          claimed: false,
          archived: false,
          has_labels: true,
          ...(!!selectedMetricCategory.category && { category: selectedMetricCategory.category }),
          ...(!!selectedMetricCategory.type_codes && {
            type_codes: selectedMetricCategory.type_codes.join(','),
          }),
        };
        let queryString = new URLSearchParams(filters);

        let response = null;

        if (!nextPage) {
          response = await listObservations(projectId, queryString, observationCancelTokenSource);
        } else {
          response = await axios.get(nextPage).then(d => d.data);
        }

        setObservationFetchNextPage(response.meta.next);
        const responseData = await response.data;

        let localAxios = axios.create();
        delete localAxios.defaults.headers.common.Authorization;
        delete localAxios.defaults.headers.common['X-API-KEY'];

        const localImagePromiseArr = responseData.map((observation: any) => {
          let url = observation?.image?.processed_image?.base_image_url;
          if (observation?.is_manual) {
            // @ts-ignore
            url = observation?.image.user_image.image_url;
          }

          return localAxios
            .get(url, {
              responseType: 'blob',
            })
            .then(r => {
              let imgUrl = urlCreator.createObjectURL(r.data);
              observation.localImage = imgUrl;
            });
        });

        await Promise.all(localImagePromiseArr);

        addSafetyObservations(responseData);
      } catch (e) {
        console.error(e);
      }
    },
    [
      addSafetyObservations,
      projectId,
      safetyState.observationDate,
      selectedMetricCategory.category,
      selectedMetricCategory.type_codes,
    ]
  );

  const loadObservations = useCallback(async () => {
    const queryParamSlug = queryParams.get('category') ?? 'ppe_compliance';

    setObservationsLoading(true);
    setObservationFetchNextPage(null);

    if (selectedDisplayMode === 'gallery' && selectedMetricCategorySlug === queryParamSlug) {
      await loadGalleryObservations();
    } else {
      observationCancelTokenSource.cancel();
      observationCancelTokenSource = axios.CancelToken.source();

      updateSafety({
        observations: [],
        activeObservation: null,
      });
    }

    setObservationsLoading(false);
  }, [
    loadGalleryObservations,
    queryParams,
    selectedDisplayMode,
    selectedMetricCategorySlug,
    updateSafety,
  ]);

  useEffect(() => {
    if (!activeObservation) {
      updateSafety({
        activeObservation: observations[0],
      });
    }
  }, [activeObservation, observations, updateSafety]);

  useEffect(() => {
    loadObservations();
  }, [loadObservations]);

  useEffect(() => {
    return () => {
      observationCancelTokenSource.cancel();
      observationCancelTokenSource = axios.CancelToken.source();
    };
  }, []);

  const loadMoreObservations = useCallback(async () => {
    await loadGalleryObservations(observationFetchNextPage);
  }, [loadGalleryObservations, observationFetchNextPage]);

  const filterSummaryForDate = useCallback(
    (slug: SafetyMetricSlug, summary: any) => {
      if (summary) {
        const projectId = BuildingState.projectId;
        const metricMetaData = safetyMetricMetaData[slug];
        const dateSummary = metricMetaData.getDateSummary(summary, projectId);

        if (dateSummary) {
          const sixMonthsAgo = new Date(
            new Date(
              new Date(safetyState.observationDate).setMonth(
                new Date(safetyState.observationDate).getMonth() - 5,
                1
              )
            ).setHours(0, 0, 0, 0)
          );
          let filteredDateSummary: any = {};
          let filteredDateEntries: any = {};

          Object.entries(dateSummary).forEach(([date, value]: any) => {
            const dateKey = new Date(date);

            if (dateKey <= safetyState.observationDate && dateKey >= sixMonthsAgo) {
              filteredDateEntries[date] = value;
            }
          });

          if (!!summary.date_summary && !!summary.date_summary[projectId]) {
            filteredDateSummary[projectId] = { ...filteredDateEntries };
          } else {
            filteredDateSummary = { ...filteredDateEntries };
          }

          return {
            ...summary,
            filtered_date_summary: { ...filteredDateSummary },
          };
        } else {
          return summary;
        }
      }

      return summary;
    },
    [BuildingState.projectId, safetyState.observationDate]
  );

  useEffect(() => {
    updateSafety({
      metricSummary: {
        lighting: filterSummaryForDate('lighting', lightingMetricSummary),
        ppe_compliance: filterSummaryForDate('ppe_compliance', ppeMetricSummary),
        guardrails: filterSummaryForDate('guardrails', guardrailObservationSummary),
        holes: filterSummaryForDate('holes', holesObservationSummary),
        clutter: filterSummaryForDate('clutter', clutterMetricSummary),
        wet_surface: filterSummaryForDate('wet_surface', wetSurfaceMetricSummary),
        fire_extinguishers: filterSummaryForDate(
          'fire_extinguishers',
          fireExtinguisherViewpointDistanceSummary
        ),
        snow_ice: filterSummaryForDate('snow_ice', snowIceMetricSummary),
      },
      metricSummaryLoadingStatus: {
        lighting: lightingMetricSummaryLoading,
        ppe_compliance: ppeMetricSummaryLoading,
        guardrails: guardrailObservationSummaryLoading,
        holes: holesObservationSummaryLoading,
        clutter: clutterMetricSummaryLoading,
        wet_surface: wetSurfaceMetricSummaryLoading,
        fire_extinguishers: fireExtinguisherViewpointDistanceSummaryLoading,
        snow_ice: snowIceMetricSummaryLoading,
      },
    });
  }, [
    clutterMetricSummary,
    clutterMetricSummaryLoading,
    filterSummaryForDate,
    fireExtinguisherViewpointDistanceSummary,
    fireExtinguisherViewpointDistanceSummaryLoading,
    guardrailObservationSummary,
    guardrailObservationSummaryLoading,
    holesObservationSummary,
    holesObservationSummaryLoading,
    lightingMetricSummary,
    lightingMetricSummaryLoading,
    ppeMetricSummary,
    ppeMetricSummaryLoading,
    snowIceMetricSummary,
    snowIceMetricSummaryLoading,
    updateSafety,
    wetSurfaceMetricSummary,
    wetSurfaceMetricSummaryLoading,
  ]);

  const observationTableData = useMemo(() => {
    if (selectedMetricCategorySlug === 'ppe') {
      return observations;
    } else if (selectedMetricCategorySlug === 'lighting') {
      return lightingMetricSummary;
    } else if (selectedMetricCategorySlug === 'clutter' && trackersSlugs.includes('clutter')) {
      return clutterMetricSummary;
    } else if (
      selectedMetricCategorySlug === 'wet_surface' &&
      trackersSlugs.includes('wet_surface')
    ) {
      return wetSurfaceMetricSummary;
    } else if (
      selectedMetricCategorySlug === 'guardrails' &&
      trackersSlugs.includes('guardrails')
    ) {
      return guardrailObservationSummary;
    } else if (selectedMetricCategorySlug === 'holes') {
      return holesObservationSummary;
    } else if (selectedMetricCategorySlug === 'fire_extinguishers') {
      return fireExtinguisherViewpointDistanceSummary;
    } else if (selectedMetricCategorySlug === 'snow_ice' && trackersSlugs.includes('snow_ice')) {
      return snowIceMetricSummary;
    }

    return [];
  }, [
    clutterMetricSummary,
    fireExtinguisherViewpointDistanceSummary,
    guardrailObservationSummary,
    holesObservationSummary,
    lightingMetricSummary,
    observations,
    selectedMetricCategorySlug,
    snowIceMetricSummary,
    trackersSlugs,
    wetSurfaceMetricSummary,
  ]);

  const windowHeight = useMemo(() => {
    return window.innerHeight - 68 - 38 - 26 - 25;
  }, []);

  return (
    <SafetyHomePageContainer>
      <LeftColumn>
        <ObservationViewContainer>
          {observationsLoading && <LoadingIndicator />}
          {!observationsLoading && (
            <>
              {selectedDisplayMode === 'gallery' && (
                <ObservationView
                  viewerId="observation-home"
                  viewerPosition={viewerPosition}
                  observation={safetyState.activeObservation}
                  onChangeViewerPosition={setViewerPosition}
                  changeSeverityDisabled
                />
              )}
              {selectedDisplayMode === 'floor_plan' && (
                <MiniFloorPlanViewContainer>
                  {selectedMetricCategorySlug === 'lighting' && (
                    <SafetyLightingFloorPlanView minimapMode />
                  )}
                  {selectedMetricCategorySlug === 'clutter' &&
                    trackersSlugs.includes('clutter') && <SafetyClutterFloorPlanView minimapMode />}
                  {selectedMetricCategorySlug === 'wet_surface' &&
                    trackersSlugs.includes('wet_surface') && (
                      <SafetyWetSurfaceFloorPlanView minimapMode />
                    )}
                  {selectedMetricCategorySlug === 'guardrails' &&
                    trackersSlugs.includes('guardrails') && (
                      <SafetyGuardrailsFloorPlanView minimapMode />
                    )}
                  {selectedMetricCategorySlug === 'fire_extinguishers' && (
                    <SafetyFireExtinguisherFloorPlanView minimapMode />
                  )}
                  {selectedMetricCategorySlug === 'snow_ice' &&
                    trackersSlugs.includes('snow_ice') && (
                      <p>SafetySnowIceFloorPlanView minimapMode</p>
                    )}
                </MiniFloorPlanViewContainer>
              )}
            </>
          )}
        </ObservationViewContainer>
        <ObservationTableContainer>
          {(metricSummaryLoading || observationsLoading) && <LoadingIndicator />}
          {!metricSummaryLoading && !observationsLoading && (
            <ObservationTable
              selectedMetricCategorySlug={selectedMetricCategorySlug}
              data={observationTableData}
              loadMoreObservations={loadMoreObservations}
              showMoreObservationsButtonVisible={!!observationFetchNextPage}
            />
          )}
        </ObservationTableContainer>
      </LeftColumn>
      <RightColumn>
        <div
          style={{
            height: windowHeight * (windowHeight < 1200 ? 0.4 : 0.5) - 10,
          }}>
          <CompositeSafetyIndexProvider>
            <CompositeSafetyIndex />
          </CompositeSafetyIndexProvider>
        </div>
        <div
          style={{
            height: windowHeight * (windowHeight < 1200 ? 0.6 : 0.5) - 10,
          }}>
          <SafetyMetrics />
        </div>
      </RightColumn>
    </SafetyHomePageContainer>
  );
};

const SafetyHomePageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 7;
  height: 100%;
`;

const ObservationViewContainer = styled.div`
  flex: 1;
  background-color: white;
`;

const ObservationTableContainer = styled.div`
  background-color: white;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 5;
  height: 100%;
`;

const MiniFloorPlanViewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
