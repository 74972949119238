import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Flex from '@react-css/flex/dist/src/components/FlexWrapper';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { ProgressTable } from '../../../common/Table/ProgressTable';
import { DateScrollSelector } from '../components/DateScrollSelector/DateScrollSelector';
import { fetchTracker, ProjectTrackerData } from '../../../../api/trackers';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { Route, Switch, useRouteMatch } from 'react-router';
import { PATH_STRINGS, useGeneratedPaths } from '../../../../hooks/useGeneratedPaths';
// import { ProgressGantt } from '../components/ProgressGantt/ProgressGantt';
import { useProgressContext } from '../../../../contexts/progressContext';
import { ProgressCharts } from '../components/ProgressCharts/ProgressCharts';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { useNavigation } from '../../../../hooks/useNavigation';
import { IconViewModeSelector } from '../../../common/IconViewModeSelector/IconViewModeSelector';
// import { ProgressReports } from '../components/ProgressReports/ProgressReports';
import iconEye from '../../../../assets/images/icon_eye.svg';
import iconEyeWhite from '../../../../assets/images/icon_eye_white.svg';
import iconBarChart from '../../../../assets/images/icon_bar_chart.svg';
import iconBarChartWhite from '../../../../assets/images/icon_bar_chart_white.svg';

export const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getPercentOverTime = (totalUnits: number, completedUnits: number, startDate: Date, endDate: Date) => {
  const percentFinished = Math.round(completedUnits / totalUnits * 100);
  const initialObservationDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  const latestObservationDate = new Date(new Date(endDate).setHours(0, 0, 0, 0));
  
  const days = Math.round((latestObservationDate.getTime() - initialObservationDate.getTime()) / (1000 * 3600 * 24)) + 1;
  return +(percentFinished / days).toFixed(2);
}

export const generateSlugLabel = (slug: string, trackerName: string, capitalize?: boolean) => {
  let slugLabel = slug
    //@ts-ignore
    .capitalize()
    .replaceAll('_', ' ')
    .replace(trackerName, '');

  if (capitalize) {
     //@ts-ignore
    return slugLabel.split(' ').map((word: string) => word.capitalize()).join(' ').trim();
  }

  return slugLabel.trim();
}

export const ProgressOverview = () => {
  const {
    generateProjectProgressPath,
    // generateProjectProgressGanttPath,
    generateProjectProgressChartsPath,
    // generateProjectProgressReportsPath,
  } = useGeneratedPaths();

  const {navigateToProjectProgressCharts} = useNavigation();

  const { state: BuildingState } = useBuildingContext();
  const { state: progressState, updateActiveTracker } = useProgressContext();
  const { trackers, activeTracker } = progressState;
  const queryParams = useQueryParams();

  const initialSelectedFloor = queryParams.get('floor') ? queryParams.get('floor') : !!BuildingState.projectData.floors ? BuildingState.projectData.floors[0].floor_code : '';
  const queryParamsDateString = queryParams.get('date');
  let initialObservationDate = new Date();

  if (queryParamsDateString) {
    const queryParamDate = new Date(queryParamsDateString);

    if (!isNaN(queryParamDate.getTime())) {
      initialObservationDate = queryParamDate;
    }
  }

  const initialStartDate = new Date(initialObservationDate);
  initialStartDate.setMonth(initialStartDate.getMonth() - 3);

  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date(initialObservationDate))
  const [observationDate, setObservationDate] = useState<Date>(initialObservationDate);
  const [trackerData, setTrackerData] = useState<ProjectTrackerData[]>([]);
  const [trackerDataLoaded, setTrackerDataLoaded] = useState<boolean>(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);
  const [contentBoxWidth, setContentBoxWidth] = useState<number | null>(null);
  const [selectedFloor, setSelectedFloor] = useState<string>(initialSelectedFloor);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const progressChartsMatch = useRouteMatch(PATH_STRINGS.progressOverviewCharts);
  const progressReportsMatch = useRouteMatch(PATH_STRINGS.progressOverviewReports);
  const tableContainerStyle = (!!progressChartsMatch || !!progressReportsMatch) ? {height: 'calc(100% - 164px)'} : {};
  
  const resizeObserver = useMemo(() => {
    return new ResizeObserver((entries) => {
      entries.forEach(entry => {
        if (entry.target === tableContainerRef.current) {
          setContentBoxWidth(entry.contentBoxSize[0].inlineSize);
        }
      });
    });
  }, []);

  const onChangeSelectedFloor = (newSelectedFloor: string) => {
    setSelectedFloor(newSelectedFloor);

    if (!!progressChartsMatch) {
      navigateToProjectProgressCharts(newSelectedFloor, activeTracker);
    }
  }

  const onChangeSelectedTracker = (newSelectedTracker: string) => {
    updateActiveTracker(newSelectedTracker);

    if (!!progressChartsMatch) {
      navigateToProjectProgressCharts(selectedFloor, newSelectedTracker);
    }
  }
  
  useEffect(() => {
    if (tableContainerRef.current) {
      resizeObserver.observe(tableContainerRef.current);
    }
  }, [resizeObserver]);

  const _getProjectData = useCallback(
    (tracker: string) => {
      return fetchTracker(BuildingState.projectId, tracker, observationDate).then(d => d.data);
    },
    [BuildingState.projectId, observationDate]
  );

  useEffect(() => {
    if (trackers.length) {
      setTrackerDataLoaded(false);

      Promise.all(trackers.map((tracker: any) => _getProjectData(tracker.type.name))).then(
        (t: any[]) => {
          setTrackerData(t);
          setTrackerDataLoaded(true);
          setInitialLoadComplete(true);
        }
      );
    }
  }, [_getProjectData, trackers]);

  const viewModes = useMemo(() => [
    {
      name: 'Dashboard',
      path: generateProjectProgressPath(BuildingState.projectId),
      icon: iconEye,
      iconActive: iconEyeWhite,
    },
    // {
    //   name: 'Gantt',
    //   generatePath: generateProjectProgressGanttPath,
    //   icon: iconCalendar,
    // },
    {
      name: 'Charts',
      path: generateProjectProgressChartsPath(BuildingState.projectId),
      icon: iconBarChart,
      iconActive: iconBarChartWhite,
    },
    // {
    //   name: 'Reports',
    //   generatePath: generateProjectProgressReportsPath,
    //   icon: iconClipboard,
    // },
  ], [BuildingState.projectId, generateProjectProgressChartsPath, generateProjectProgressPath]);

  return (
    <>
      <SecondaryMenuBar>
        <Flex
          alignItems="center"
        >
          {progressChartsMatch &&
            <>
              <DateScrollSelector
                justify="flex-start"
                date={startDate}
                setDate={d => setStartDate(d)}
                hideViewButtons
              />
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: '#94A3B8',
                }}
              >
                to
              </div>
              <DateScrollSelector
                justify="flex-start"
                date={endDate}
                setDate={d => setEndDate(d)}
                hideViewButtons
              />
            </>
          }
          {(!progressReportsMatch && !progressChartsMatch) &&
            <DateScrollSelector
              justify="flex-start"
              date={observationDate}
              setDate={d => setObservationDate(d)}
              showSkipWeekButtons
            />
          }
          {!!progressChartsMatch && !!activeTracker &&
            <>
              <Flex>
                <Select
                  value={selectedFloor}
                  onChange={e => onChangeSelectedFloor(e.target.value)}
                >
                  {BuildingState.projectData.floors.map((floor: any) => {
                    return(
                      <option
                        key={floor.floor_code}
                        value={floor.floor_code}
                      >
                        {floor.name}
                      </option>
                    )
                  })}
                </Select>
              </Flex>
              <Flex>
                <Select
                  value={activeTracker}
                  onChange={e => onChangeSelectedTracker(e.target.value)}
                >
                  {trackers.map((tracker: any) => {
                    return(
                      <option
                        key={tracker.type.name}
                        value={tracker.type.name}
                      >
                        {tracker.type.name}
                      </option>
                    )
                  })}
                </Select>
              </Flex>
            </>
          }
        </Flex>
        <Flex alignItems="center">
          {/* {!progressReportsMatch &&
            <Select>
              <option>Breakdown by %</option>
              <option>Breakdown by units</option>
            </Select>
          } */}
          <Flex
            style={{gap: '8px'}}
          >
            <IconViewModeSelector
              modes={viewModes}
            />
          </Flex>
        </Flex>
      </SecondaryMenuBar>
      <TableContainer
        ref={tableContainerRef}
        style={tableContainerStyle}
      >
        {(!trackerData.length || !trackerDataLoaded) && (
          <LoadingIndicator
            containerStyle={{
              position: 'absolute',
              width: 'calc(100% - 40px)',
              height: 'calc(100% - 40px)'
            }}
          />
        )}
        {(!!trackerData.length && initialLoadComplete) && (
          <Switch>
            <Route exact path={PATH_STRINGS.progressOverview}>
              <ProgressTable
                trackerData={trackerData}
                pageSize={(trackerData[0].floors.length ?? 10) + 1}
              />
            </Route>
            {/* <Route exact path={PATH_STRINGS.progressOverviewGantt}>
              <ProgressGantt
                containerWidth={contentBoxWidth}
                trackerData={trackerData}
              />
            </Route> */}
            <Route exact path={PATH_STRINGS.progressOverviewCharts}>
              <ProgressCharts
                selectedFloor={selectedFloor}
                startDate={startDate}
                endDate={endDate}
              />
            </Route>
            {/* <Route exact path={PATH_STRINGS.progressOverviewReports}>
              <ProgressReports/>
            </Route> */}
          </Switch>
        )}
      </TableContainer>
    </>
  );
};

export const ParentHeader = styled.div`
  text-align: left;
  padding: 1em 0;
  color: #212121;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
`;
const TableContainer = styled.div`
  background: #fff;
  padding: 20px;
  margin: auto;
  width: 100%;
  position: relative;
  min-height: 75px;
`;

const SecondaryMenuBar = styled.div`
  width: 100%;
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Select = styled.select`
  background: transparent;
  border: 1px solid #073c7a;
  border-radius: 2px;
  color: #073c7a;
  font-size: 14px;
  padding: 0 4px 0 4px;
  min-width: 112px;
  height: 30px;
  margin: 0 10px;
`;
