import React, { memo, useCallback, useEffect } from 'react';
import { useContextMenu } from '../../../../hooks/useContextMenu';
import { useTagContext } from '../../../../../../../contexts/tagContext';
import { useBuildingContext } from '../../../../../../../contexts/buildingContext';
import { HotspotContextMenu } from './HotspotContextMenu/HotspotContextMenu';
import { ContextMenuContainer, MenuItem } from './styles';
import mixpanel from 'mixpanel-browser';
import { saveAs } from 'file-saver';
import { PermissionWrappedComponent } from '../../../../../../common/PermissionWrapper/PermissionWrapper';
import { MapPointContainer, MapPointMainCircle } from '../../../../../../common/MapViewer';
import classNames from 'classnames';
import styled from 'styled-components';
import iconAnnotationGray from '../../../../../../../assets/images/icon_annotation_gray.svg';
import iconAnnotationWhite from '../../../../../../../assets/images/icon_annotation_white.svg';
import iconCameraGray from '../../../../../../../assets/images/icon_camera_gray.svg';
import iconCameraWhite from '../../../../../../../assets/images/icon_camera_white.svg';
import iconDangerWhite from '../../../../../../../assets/images/icon_danger.svg';
import iconDangerGray from '../../../../../../../assets/images/icon_danger_gray.svg';
import iconProcoreGray from '../../../../../../../assets/images/icon_procore_gray.svg';
import iconProcoreWhite from '../../../../../../../assets/images/icon_procore_white.svg';
import iconAddChatGray from '../../../../../../../assets/images/icon_add_chat_gray.svg';
import iconAddChatWhite from '../../../../../../../assets/images/icon_add_chat_white.svg';
import { useProcoreAuthContext } from '../../../../../../../contexts/procoreAuthContext';
import { usePannellumViewer } from '../../../../../../../hooks/usePannellum';

interface ImageViewerContextMenuProps {
  viewerId: string;
  onTakeScreenshot(callback: (blob: Blob) => void): void;
}
export const ImageViewerContextMenu = memo(
  ({ viewerId, onTakeScreenshot }: ImageViewerContextMenuProps) => {
    const { state: buildingState } = useBuildingContext();
    const { updateTags, updateChat, findSetCurrentTag } = useTagContext();
    const { xPos, yPos, showMenu, isHotspot, pitch, yaw } = useContextMenu(viewerId);
    const { state: procoreAuthState } = useProcoreAuthContext();
    const { viewer, addHotSpot } = usePannellumViewer(viewerId);
    const {
      isAuthenticated: isProcoreAuthenticated,
      canCreateRfis,
      canReadRfis,
      canUploadImages,
    } = procoreAuthState;

    const projectIsLinkedToProcore =
      buildingState &&
      buildingState.projectData &&
      buildingState.projectData.procore_company_id &&
      buildingState.projectData.procore_project_id;
    const showProcoreMenuItems = isProcoreAuthenticated && projectIsLinkedToProcore;

    useEffect(() => {
      console.log('isProcoreAuthenticated:', isProcoreAuthenticated);
      console.log('projectIsLinkedToProcore:', projectIsLinkedToProcore);
    }, [isProcoreAuthenticated, projectIsLinkedToProcore]);

    const hospotClickHandler = (
      event: any,
      args: { pitch: number; yaw: number; type: string; tagId?: number }
    ) => {
      if (args.tagId) {
        findSetCurrentTag(args.tagId);
        mixpanel.track('Click Hotspot', {
          type: args.type,
        });
      }
    };

    const hotspotContextMenuHandler = (event: MouseEvent, args: { tagId: number }) => {
      if (args.tagId) findSetCurrentTag(args.tagId, true);
    };

    const addMyHotspot = (e: any, type: string, text?: string) => {
      if (viewer && pitch !== null && yaw !== null) {
        let coordinates: any = [pitch, yaw];
        const id = Math.round(Math.random() * -100);
        addHotSpot(coordinates, type, hospotClickHandler, hotspotContextMenuHandler, id, 0, text);
        updateTags({
          current: {
            id: id,
            pitch: coordinates[0],
            yaw: coordinates[1],
            project_id: buildingState.projectId,
            point_id: buildingState.pointId,
            type: type,
          },
        });
        if (type === 'CHAT') {
          updateChat({ displayDialogue: true, status: 'create' });
        }
        mixpanel.track('Add Tag', {
          type: type,
        });
      }
    };

    const handleDownloadClick = useCallback(() => {
      onTakeScreenshot(blob => {
        saveAs(blob!, 'screen.png');
      });
    }, [onTakeScreenshot]);

    if (isHotspot)
      return <HotspotContextMenu viewerId={viewerId} xPos={xPos} yPos={yPos} showMenu={showMenu} />;

    return (
      <div>
        {showMenu && (
          <MapPointContainer
            x={parseInt(xPos)}
            y={parseInt(yPos)}
            isActive={false}
            style={{ zIndex: 10000 }}>
            <MapPointMainCircle
              pointSize={14}
              borderSize={1}
              className={classNames({
                back_blue: true,
                border_white: true,
              })}
            />
          </MapPointContainer>
        )}

        <ContextMenuContainer
          style={{
            top: yPos,
            left: parseInt(xPos) + 9,
            display: showMenu ? 'block' : 'none',
            padding: '0px',
          }}>
          <ul>
            <MenuItem
              onClick={e => addMyHotspot(e, 'CHAT')}
              className="pnlm-ignore context-menu-li"
              icon={iconAddChatGray}
              hoverIcon={iconAddChatWhite}
              style={menuItemStyle}>
              Chat
            </MenuItem>
            <MenuItem
              onClick={e => addMyHotspot(e, 'ANNOTATION')}
              className="pnlm-ignore context-menu-li"
              icon={iconAnnotationGray}
              hoverIcon={iconAnnotationWhite}
              style={menuItemStyle}>
              Annotation
            </MenuItem>
            <MenuItem
              onClick={handleDownloadClick}
              className="pnlm-ignore"
              icon={iconCameraGray}
              hoverIcon={iconCameraWhite}
              style={menuItemStyle}>
              Screenshot
            </MenuItem>
            <PermissionWrappedComponent
              permission="safety_tables.create_observation"
              project_id={buildingState.projectId}>
              <MenuSectionDivider />
              <MenuItem
                onClick={e => addMyHotspot(e, 'SAFETY')}
                className="pnlm-ignore context-menu-li"
                icon={iconDangerGray}
                hoverIcon={iconDangerWhite}
                style={menuItemStyle}>
                Safety Observation
              </MenuItem>
            </PermissionWrappedComponent>
            {showProcoreMenuItems && (
              <>
                {(canCreateRfis || canReadRfis || canUploadImages) && <MenuSectionDivider />}
                {canCreateRfis && (
                  <MenuItem
                    onClick={e => addMyHotspot(e, 'PROCORE-NEW-RFI')}
                    className="pnlm-ignore context-menu-li"
                    icon={iconProcoreGray}
                    hoverIcon={iconProcoreWhite}
                    style={menuItemStyle}>
                    New RFI
                  </MenuItem>
                )}
                {canReadRfis && (
                  <MenuItem
                    onClick={e => addMyHotspot(e, 'PROCORE-LINK-RFI')}
                    className="pnlm-ignore context-menu-li"
                    icon={iconProcoreGray}
                    hoverIcon={iconProcoreWhite}
                    style={menuItemStyle}>
                    Link Existing RFI
                  </MenuItem>
                )}
                {canUploadImages && (
                  <MenuItem
                    onClick={e => addMyHotspot(e, 'PROCORE-SAVE-IMAGE')}
                    className="pnlm-ignore context-menu-li"
                    icon={iconProcoreGray}
                    hoverIcon={iconProcoreWhite}
                    style={menuItemStyle}>
                    Save Image to Procore
                  </MenuItem>
                )}
              </>
            )}
          </ul>
        </ContextMenuContainer>
      </div>
    );
  }
);

ImageViewerContextMenu.displayName = 'ImageViewerContextMenu';

const menuItemStyle = {
  padding: '5px 10px',
};

const MenuSectionDivider = styled.div`
  border-top: 1px solid rgba(107, 114, 128, 0.5);
`;
