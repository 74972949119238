import { useTagContext } from "../../../contexts/tagContext";
import { NewRFIForm } from "./components/NewRFIForm";
import { useCallback, useEffect, useState } from "react";
import { LinkExistingRFIForm } from "./components/LinkExistingRFIForm";
import { SaveImageForm } from "./components/SaveImageForm";
import { ProcoreManagerContainer } from "./components/styles";
import { CreatedRFI } from "./components/CreatedRFI";
import { useImageViewerContext } from "../../views/image_viewer/imageViewerContext";
import { ViewerPosition } from "../../views/image_viewer/types";
import { usePannellumViewer } from "../../../hooks/usePannellum";

interface ProcoreManagerProps {
  viewerId: string;
  viewerPosition: ViewerPosition;
  hydrate: (tagsToUpdate?: number[]) => Promise<any>;
  onTakeScreenshot(callback: (blob: Blob) => void): void;
}

export const ProcoreManager = ({
  viewerId,
  viewerPosition,
  hydrate,
  onTakeScreenshot,
}: ProcoreManagerProps) => {
  const [transform, setTransform] = useState<string>("");
  const { state: tagState, toggleDisplay } = useTagContext();
  const { state: ImageViewerState } = useImageViewerContext();
  const { viewer, removeHotSpot } = usePannellumViewer(viewerId);
  const currentTag = tagState.tags.current;

  const renderDialogue = useCallback(() => {
    let dom = document.getElementById("rfi-container");
    if (dom && viewer && currentTag) {
      let ctya = currentTag.yaw,
        canvasPitch = viewerPosition.pitch,
        canvasYaw = viewerPosition.yaw - ImageViewerState.master.data.angle,
        canvasHfov = viewerPosition.hfov,
        hsPitchSin = Math.sin((currentTag.pitch * Math.PI) / 180),
        hsPitchCos = Math.cos((currentTag.pitch * Math.PI) / 180),
        configPitchSin = Math.sin((canvasPitch * Math.PI) / 180),
        configPitchCos = Math.cos((canvasPitch * Math.PI) / 180),
        yawCos = Math.cos(((-ctya + canvasYaw) * Math.PI) / 180);
      var z =
        hsPitchSin * configPitchSin + hsPitchCos * yawCos * configPitchCos;
      if (
        (ctya <= 90 && ctya > -90 && z <= 0) ||
        ((ctya > 90 || ctya <= -90) && z <= 0)
      ) {
        console.log("no visible");
      } else {
        var yawSin = Math.sin(((-ctya + canvasYaw) * Math.PI) / 180),
          hfovTan = Math.tan((canvasHfov * Math.PI) / 360);
        // Subpixel rendering doesn't work in Firefox
        // https://bugzilla.mozilla.org/show_bug.cgi?id=739176
        var canvas = viewer.getRenderer().getCanvas(),
          canvasWidth = canvas.clientWidth,
          canvasHeight = canvas.clientHeight;
        var coord = [
          ((-canvasWidth / hfovTan) * yawSin * hsPitchCos) / z / 2,
          ((-canvasWidth / hfovTan) *
            (hsPitchSin * configPitchCos -
              hsPitchCos * yawCos * configPitchSin)) /
            z /
            2,
        ];
        // Apply transform
        coord[0] += (canvasWidth - dom.offsetWidth + 410) / 2;
        coord[1] += (canvasHeight - dom.offsetHeight - 165) / 2;

        const margin = 25;
        const translateX = Math.min(
          Math.max(coord[0], margin),
          canvasWidth - dom.offsetWidth - margin,
        );
        const translateY = Math.min(
          Math.max(coord[1], margin),
          canvasHeight - dom.offsetHeight - margin,
        );

        const t = `translate(${translateX}px, ${translateY}px) translateZ(9999px)`;
        setTransform(t);
      }
    }
  }, [currentTag, viewer, ImageViewerState.master.data.angle]);

  useEffect(() => {
    renderDialogue();
  });

  const onCloseForm = () => {
    toggleDisplay();
    removeHotSpot(currentTag.id);
  };

  return (
    <>
      {tagState.displayDialogue && (
        <ProcoreManagerContainer
          id="rfi-container"
          className="pnlm-ignore oco-dialogue"
          style={{ transform: transform }}
        >
          {currentTag.type === "PROCORE-NEW-RFI" && (
            <NewRFIForm onCloseForm={onCloseForm} hydrate={hydrate} />
          )}
          {currentTag.type === "PROCORE-LINK-RFI" && (
            <LinkExistingRFIForm onCloseForm={onCloseForm} hydrate={hydrate} />
          )}
          {currentTag.type === "PROCORE-SAVE-IMAGE" && (
            <SaveImageForm
              onCloseForm={onCloseForm}
              onTakeScreenshot={onTakeScreenshot}
            />
          )}
          {currentTag.type === "RFI" && <CreatedRFI hydrate={hydrate} />}
        </ProcoreManagerContainer>
      )}
    </>
  );
};
