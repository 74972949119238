import axios from 'axios';

const BASE_URL = process.env.REACT_APP_NEXTERA_API;

export const holesTypeCodes = ['FALL_ROTECTION_OPENING_PROTECTED', 'FALL_ROTECTION_FLR_COVRS'];

export type SafetyMetricSlug =
  | 'ppe_compliance'
  | 'guardrails'
  | 'lighting'
  | 'fire_extinguishers'
  | 'holes'
  | 'clutter'
  | 'wet_surface'
  | 'snow_ice';
export type SafetyMetricDetailDisplayMode = 'gallery' | 'floor_plan';

export interface SafetyMetricMetadataEntry {
  display_mode: SafetyMetricDetailDisplayMode;
  category?: string;
  color: string;
  order: number;
  metric_tile_name: string;
  view_mode_menu_name: string;
  type_codes?: string[];
  calulate_metric_summary_value: (metric: any) => number;
  dateSelectorTitle?: string;
  getProjectSummary: (overallSummary: any, projectId: string) => any;
  getDateSummary: (overallSummary: any, projectId: string) => any;
  getFilteredDateSummary: (overallSummary: any, projectId: string) => any;
  weight: number;
}

export type SafetyMetricMetaData = Record<SafetyMetricSlug, SafetyMetricMetadataEntry>;

const divide = (numerator: number, denominator: number) => {
  return denominator === 0 ? 1 : numerator / denominator;
};

export const safetyMetricMetaData: SafetyMetricMetaData = {
  ppe_compliance: {
    display_mode: 'gallery',
    category: 'PPE',
    color: '#4339F2',
    order: 1,
    metric_tile_name: 'PPE Compliance',
    view_mode_menu_name: 'PPE',
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.fully_compliant, metric._total);
    },
    getProjectSummary: (overallSummary: any, projectId: string) =>
      overallSummary ? overallSummary.floor_summary[projectId]?._all : null,
    getDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.date_summary ? overallSummary.date_summary[projectId] : null,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.filtered_date_summary
        ? overallSummary.filtered_date_summary[projectId]
        : null,
    weight: 1 / 7,
  },
  guardrails: {
    display_mode: 'floor_plan',
    metric_tile_name: 'Guardrails',
    view_mode_menu_name: 'Guardrails',
    color: '#14B8A6',
    order: 2,
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.items - metric.unsafe, metric.items);
    },
    dateSelectorTitle: 'Guardrails As Of',
    getProjectSummary: (overallSummary: any, projectId: string) =>
      overallSummary ? overallSummary.floor_summary[projectId]?._all : null,
    getDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.date_summary ? overallSummary.date_summary[projectId] : null,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.filtered_date_summary
        ? overallSummary.filtered_date_summary[projectId]
        : null,
    weight: 1 / 7,
  },
  lighting: {
    metric_tile_name: 'Lighting',
    view_mode_menu_name: 'Lighting',
    display_mode: 'floor_plan',
    color: '#22D3EE',
    order: 3,
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.compliant, metric._total);
    },
    dateSelectorTitle: 'Lighting As Of',
    getProjectSummary: (overallSummary: any, projectId: string) =>
      overallSummary ? overallSummary.floor_summary[projectId]?._all : null,
    getDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.date_summary ? overallSummary.date_summary[projectId] : null,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.filtered_date_summary
        ? overallSummary.filtered_date_summary[projectId]
        : null,
    weight: 1 / 7,
  },
  fire_extinguishers: {
    metric_tile_name: 'Fire Extinguishers',
    view_mode_menu_name: 'Fire',
    display_mode: 'floor_plan',
    color: '#8B5CF6',
    order: 4,
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.compliant, metric._total);
    },
    dateSelectorTitle: 'Fire Extinguishers As Of',
    getProjectSummary: (overallSummary: any, projectId: string) =>
      overallSummary ? overallSummary.floor_summary[projectId]?._all : null,
    getDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.date_summary ? overallSummary.date_summary[projectId] : null,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.filtered_date_summary
        ? overallSummary.filtered_date_summary[projectId]
        : null,
    weight: 1 / 7,
  },
  holes: {
    metric_tile_name: 'Holes',
    view_mode_menu_name: 'Holes',
    display_mode: 'gallery',
    type_codes: holesTypeCodes,
    color: '#FB7185',
    order: 5,
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.safe, metric.total);
    },
    getProjectSummary: (overallSummary: any, projectId: string) => overallSummary,
    getDateSummary: (overallSummary: any, projectId: string) => overallSummary?.date_summary,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary?.filtered_date_summary,
    weight: 1 / 7,
  },
  clutter: {
    metric_tile_name: 'Clutter',
    view_mode_menu_name: 'Clutter',
    display_mode: 'floor_plan',
    color: '#10B981',
    order: 6,
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.signal, metric.total_units);
    },
    dateSelectorTitle: 'Clutter As Of',
    getProjectSummary: (overallSummary: any, projectId: string) =>
      overallSummary ? overallSummary.floor_summary[projectId]?._all : null,
    getDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.date_summary ? overallSummary.date_summary[projectId] : null,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.filtered_date_summary
        ? overallSummary.filtered_date_summary[projectId]
        : null,
    weight: 1 / 7,
  },
  wet_surface: {
    metric_tile_name: 'Wet Surface',
    view_mode_menu_name: 'Wet Surface',
    display_mode: 'floor_plan',
    color: '#10B981',
    order: 7,
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.inverse_signal, metric.total_units);
    },
    dateSelectorTitle: 'Wet Surfaces As Of',
    getProjectSummary: (overallSummary: any, projectId: string) =>
      overallSummary ? overallSummary.floor_summary[projectId]?._all : null,
    getDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.date_summary ? overallSummary.date_summary[projectId] : null,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.filtered_date_summary
        ? overallSummary.filtered_date_summary[projectId]
        : null,
    weight: 1 / 7,
  },
  snow_ice: {
    metric_tile_name: 'Snow and Ice',
    view_mode_menu_name: 'Snow and Ice',
    display_mode: 'floor_plan',
    color: '#10B981',
    order: 8,
    calulate_metric_summary_value: (metric: any) => {
      return divide(metric.inverse_signal, metric.total_units);
    },
    dateSelectorTitle: 'Snow and Ice As Of',
    getProjectSummary: (overallSummary: any, projectId: string) =>
      overallSummary ? overallSummary.floor_summary[projectId]?._all : null,
    getDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.date_summary ? overallSummary.date_summary[projectId] : null,
    getFilteredDateSummary: (overallSummary: any, projectId: string) =>
      overallSummary && overallSummary.filtered_date_summary
        ? overallSummary.filtered_date_summary[projectId]
        : null,
    weight: 1 / 7,
  },
};

type LightingMetricParamNames = 'project_id' | 'floor_code';
type LightingMetricParams = Partial<Record<LightingMetricParamNames, string>>;

export interface LightingMetric {
  id: number;
  viewpoints_image_id: number;
  foot_candles: string;
  taken_on: string;
  viewpoint: number;
}

interface LightingCounts {
  foot_candles: number;
  compliant: number;
  _total: number;
}

export type LightingMetricFloorSummary = { _all: LightingCounts } & Partial<
  Record<string, { _all: LightingCounts } & Record<string, LightingCounts>>
>;
export type LightingMetricDateSummary = Partial<Record<string, Record<string, LightingCounts>>>;
export interface LightingMetricSummary {
  floor_summary: LightingMetricFloorSummary;
  date_summary: LightingMetricDateSummary;
}

export const fetchLightingMetrics = async (
  params?: LightingMetricParams
): Promise<LightingMetric[]> => {
  const res = await axios.get(`${BASE_URL}/safety/lighting-metrics`, {
    params: params,
  });
  const response = await res.data;
  return response.data;
};

export const fetchLightingMetricSummary = async (
  params?: LightingMetricParams
): Promise<LightingMetricSummary> => {
  const res = await axios.get(`${BASE_URL}/safety/lighting-metrics/summary`, {
    params: params,
  });
  const response = await res.data;
  return response.data;
};

type PPEMetricParamNames = 'project_id' | 'floor_code';
type PPEMetricParams = Partial<Record<PPEMetricParamNames, string>>;

export interface PPEMetric {
  id: number;
  viewpoints_image_id: number;
  hard_hat_compliant: boolean;
  high_visibility_vest_compliant: boolean;
  gloves_compliant: boolean;
  glasses_compliant: boolean;
  fully_compliant: boolean;
}

export type PPEMetricFloorSummary = { _all: ComplianceCounts } & Partial<
  Record<string, { _all: ComplianceCounts } & Record<string, ComplianceCounts>>
>;
export type PPEMetricDateSummary = Partial<Record<string, Record<string, ComplianceCounts>>>;
export interface PPEMetricSummary {
  floor_summary: PPEMetricFloorSummary;
  date_summary: PPEMetricDateSummary;
}

interface ComplianceCounts {
  fully_compliant: number;
  _total: number;
}

export const fetchPPEMetrics = async (params?: PPEMetricParams): Promise<PPEMetric[]> => {
  const res = await axios.get(`${BASE_URL}/safety/ppe-metrics`, {
    params: params,
  });
  const response = await res.data;
  return response.data;
};

export const fetchPPEMetricSummary = async (
  params?: PPEMetricParams
): Promise<PPEMetricSummary> => {
  const res = await axios.get(`${BASE_URL}/safety/ppe-metrics/summary`, {
    params: params,
  });
  const response = await res.data;
  return response.data;
};

type FireExtinguisherParamNames = 'project_id' | 'floor_code';
type FireExtinguisherParams = Partial<Record<FireExtinguisherParamNames, string>>;

export interface FireExtinguisherViewpointsDistanceMetric {
  id: number;
  viewpoints_image_id: number;
  distance: number;
  compliant: boolean;
  taken_on: string;
  viewpoint_id: number;
}

export const fetchFireExtinguisherViewpointDistanceMetrics = async (
  params?: FireExtinguisherParams
): Promise<FireExtinguisherViewpointsDistanceMetric[]> => {
  const res = await axios.get(`${BASE_URL}/safety/fire-extinguishers/viewpoint-distance-metrics`, {
    params: params,
  });
  const response = await res.data;
  return response.data;
};

interface FireExtinguisherViewpointDistanceCounts {
  distance: number;
  compliant: number;
  _total: number;
}

export type FireExtinguisherViewpointDistanceFloorSummary = {
  _all: FireExtinguisherViewpointDistanceCounts;
} & Partial<
  Record<
    string,
    { _all: FireExtinguisherViewpointDistanceCounts } & Record<
      string,
      FireExtinguisherViewpointDistanceCounts
    >
  >
>;
export type FireExtinguisherViewpointDistanceDateSummary = Partial<
  Record<string, Record<string, FireExtinguisherViewpointDistanceCounts>>
>;
export interface FireExtinguisherViewpointDistanceSummary {
  floor_summary: FireExtinguisherViewpointDistanceFloorSummary;
  date_summary: FireExtinguisherViewpointDistanceDateSummary;
}

export const fetchFireExtinguisherViewpointDistanceSummary = async (
  params?: FireExtinguisherParams
): Promise<FireExtinguisherViewpointDistanceSummary> => {
  const res = await axios.get(
    `${BASE_URL}/safety/fire-extinguishers/viewpoint-distance-metrics/summary`,
    {
      params: params,
    }
  );
  const response = await res.data;
  return response.data;
};

type GuardrailObservationParamNames = 'project_id' | 'floor_code';
type GuardrailObservationParams = Partial<Record<GuardrailObservationParamNames, string>>;
export type GuardrailDesignation = 'safe' | 'unsafe';

export interface GuardrailObservation {
  id: number;
  viewpoints_image_id: number;
  item_id: number;
  observed_quantity: number;
  viewpoint: number;
  coordinates: string;
  observation_id: number;
  label_type_id: number;
  registered_on: string;
  designation: GuardrailDesignation;
}

export const fetchGuardrailObservations = async (
  params?: GuardrailObservationParams
): Promise<GuardrailObservation[]> => {
  const res = await axios.get(`${BASE_URL}/safety/guardrails/observations`, {
    params: params,
  });
  const response = await res.data;
  return response.data;
};

interface GuardrailObservationCounts {
  safe: number;
  unsafe: number;
  _total: number;
}

interface GuardrailObservationCountsWithItems extends GuardrailObservationCounts {
  items: number;
}

export type GuardrailObservationFloorSummary = { _all: GuardrailObservationCounts } & Partial<
  Record<
    string,
    { _all: GuardrailObservationCountsWithItems } & Record<
      string,
      GuardrailObservationCountsWithItems
    >
  >
>;
export type GuardrailObservationDateSummary = Partial<
  Record<string, Record<string, GuardrailObservationCountsWithItems>>
>;
export interface GuardrailObservationSummary {
  floor_summary: GuardrailObservationFloorSummary;
  date_summary: GuardrailObservationDateSummary;
}

export const fetchGuardrailObservationSummary = async (
  params?: GuardrailObservationParams
): Promise<GuardrailObservationSummary> => {
  const res = await axios.get(`${BASE_URL}/safety/guardrails/observations/summary`, {
    params: params,
  });
  const response = await res.data;
  return response.data;
};

export interface ClutterMetric {
  id: number;
  viewpoints_image_id: number;
  cluttered: boolean;
  taken_on: string;
  viewpoint: number;
}

interface ProjectTrackerMetricCounts {
  signal: number;
  inverse_signal: number;
  total_units: number;
  _total: number;
}

export type ProjectTrackerMetricFloorSummary = { _all: ProjectTrackerMetricCounts } & Partial<
  Record<string, { _all: ProjectTrackerMetricCounts } & Record<string, ProjectTrackerMetricCounts>>
>;
export type ProjectTrackerMetricDateSummary = Partial<
  Record<string, Record<string, ProjectTrackerMetricCounts>>
>;
export interface ProjectTrackerMetricSummary {
  floor_summary: ProjectTrackerMetricFloorSummary;
  date_summary: ProjectTrackerMetricDateSummary;
}

export const fetchProjectTrackerMetricSummary = async (
  projectId: string,
  trackerName: string
): Promise<ProjectTrackerMetricSummary> => {
  // trackerName may contain slashes, so we need to encode it
  const trackerNameEncoded = encodeURIComponent(trackerName);
  const res = await axios.get(
    `${BASE_URL}/project/${projectId}/tracker/${trackerNameEncoded}/summary`
  );
  const response = await res.data;
  return response.data;
};
