import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@react-css/flex';
import { useHistory, Link } from 'react-router-dom';
import { useAdminZoneContext } from '../../../../contexts/adminZoneContext';

const ViewModeButton = styled.div`
  font-size: 14px;
  min-width: 90px;
  width: 100%;
  color: #a7aabd;
  text-align: center;
  padding: 6px 11px;
  font-weight: 400;

  &:not(last-child) {
    margin-right: 10px;
  }

  cursor: pointer;
  user-select: none;

  &.active {
    font-weight: 700;
    color: #f8f8f8;
    background-color: #073c7a;

    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
`;
ViewModeButton.displayName = 'ViewModeButton';

interface LinkedViewModeButtonProps {
  viewName: string;
  path: string;
  active: boolean;
  disabled: boolean;
}
const LinkedViewModeButton = ({ viewName, path, active, disabled }: LinkedViewModeButtonProps) => {
  if (disabled) {
    return <ViewModeButton className={active ? 'active' : ''}>{viewName}</ViewModeButton>;
  }
  return (
    <Link to={path}>
      <ViewModeButton className={active ? 'active' : ''}>{viewName}</ViewModeButton>
    </Link>
  );
};

type StringMap = Record<string, any>;

export interface ViewModeMenuProps {
  items: StringMap;
  matchQueryParams?: boolean;
  containerStyle?: React.CSSProperties;
  zIndex?: number;
}

export const ViewModeMenu = ({
  items,
  matchQueryParams = false,
  zIndex = 10,
  containerStyle = {},
}: ViewModeMenuProps) => {
  const history = useHistory();
  const location = history.location;
  const fullPath = location.pathname + location.search;
  const { inAdminZone } = useAdminZoneContext();
  const [activeItem, setActiveItem] = useState<string>(inAdminZone ? 'Schedule' : 'Site View');

  /* Listen for route changes */
  useEffect(() => {
    const pathToMatch = matchQueryParams ? fullPath : location.pathname;

    let viewMode = Object.keys(items).find((key: string) => {
      const routeMatchesCurrentPathName = items[key].route === pathToMatch;
      const pathNameInActivePaths =
        items[key].additionalActivePaths &&
        items[key].additionalActivePaths.find((path: string) => path === pathToMatch);

      return routeMatchesCurrentPathName || pathNameInActivePaths;
    });
    if (viewMode) setActiveItem(viewMode);
  }, [fullPath, items, location.pathname, matchQueryParams]);

  return (
    <Flex id="view-mode-menu-container" style={{ ...containerStyle, zIndex: zIndex }}>
      {Object.keys(items)
        .filter(item => items[item].display)
        .filter(item => items[item].userCanAccess)
        .map(item => (
          <LinkedViewModeButton
            viewName={item}
            path={items[item].route}
            disabled={!items[item].userCanAccess}
            active={item === activeItem}
            key={item}
          />
        ))}
    </Flex>
  );
};
