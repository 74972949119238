import { Annotation } from "./components/Annotation";
import { useTagContext } from "../../../contexts/tagContext";
import { useEffect, useState } from "react";
import { useImageViewerContext } from "../../views/image_viewer/imageViewerContext";
import { ViewerPosition } from "../../views/image_viewer/types";
import { usePannellumViewer } from "../../../hooks/usePannellum";

interface AnnotationManagerProps {
  tag: any;
  viewerId: string;
  hydrate: any;
  viewerPosition: ViewerPosition;
}

export const AnnotationManager = ({
  viewerId,
  hydrate,
  viewerPosition,
}: AnnotationManagerProps) => {
  const { state: ImageViewerState } = useImageViewerContext();
  const [transform, setTransform] = useState<string>("");
  const { state: tagState } = useTagContext();
  const { viewer } = usePannellumViewer(viewerId);
  const currentTag = useTagContext().state.tags.current;

  useEffect(() => {
    let dom = document.getElementById("annotation-container");
    if (dom && viewer) {
      let ctya = currentTag.yaw,
        canvasPitch = viewerPosition.pitch,
        canvasYaw = viewerPosition.yaw - ImageViewerState.master.data.angle,
        canvasHfov = viewerPosition.hfov,
        hsPitchSin = Math.sin((currentTag.pitch * Math.PI) / 180),
        hsPitchCos = Math.cos((currentTag.pitch * Math.PI) / 180),
        configPitchSin = Math.sin((canvasPitch * Math.PI) / 180),
        configPitchCos = Math.cos((canvasPitch * Math.PI) / 180),
        yawCos = Math.cos(((-ctya + canvasYaw) * Math.PI) / 180);
      var z =
        hsPitchSin * configPitchSin + hsPitchCos * yawCos * configPitchCos;
      if (
        (ctya <= 90 && ctya > -90 && z <= 0) ||
        ((ctya > 90 || ctya <= -90) && z <= 0)
      ) {
        console.log("no visible");
      } else {
        var yawSin = Math.sin(((-ctya + canvasYaw) * Math.PI) / 180),
          hfovTan = Math.tan((canvasHfov * Math.PI) / 360);
        // Subpixel rendering doesn't work in Firefox
        // https://bugzilla.mozilla.org/show_bug.cgi?id=739176
        var canvas = viewer.getRenderer().getCanvas(),
          canvasWidth = canvas.clientWidth,
          canvasHeight = canvas.clientHeight;
        var coord = [
          ((-canvasWidth / hfovTan) * yawSin * hsPitchCos) / z / 2,
          ((-canvasWidth / hfovTan) *
            (hsPitchSin * configPitchCos -
              hsPitchCos * yawCos * configPitchSin)) /
            z /
            2,
        ];
        // Apply transform
        coord[0] += (canvasWidth - dom.offsetWidth + 275) / 2;
        coord[1] += (canvasHeight - dom.offsetHeight - 165) / 2;
        var t =
          "translate(" +
          coord[0] +
          "px, " +
          coord[1] +
          "px) translateZ(9999px)";
        setTransform(t);
      }
    }
  });

  return (
    <>
      {tagState.displayDialogue && currentTag.type === "ANNOTATION" && (
        <Annotation
          annotation={currentTag.annotation}
          transform={transform}
          hydrate={hydrate}
        />
      )}
    </>
  );
};
