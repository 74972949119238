import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATH_STRINGS } from '../../../hooks/useGeneratedPaths';
import { SafetyContextProvider } from '../../../contexts/safetyContext';
import { SafetyOverview } from './SafetyOverview/SafetyOverview';
import { SafetyMetricDetail } from './SafetyMetricDetail/SafetyMetricDetail';
import { PannellumViewersProvider } from '../../../contexts/pannellumContext';
import { RealtimePositionProvider } from '../../../contexts/realtimeContext';

export const SafetyPage = () => (
  <SafetyContextProvider>
    <RealtimePositionProvider>
      <PannellumViewersProvider>
        <SafetyContextProvider>
          <Switch>
            <Route exact path={[PATH_STRINGS.safetyOverview, PATH_STRINGS.safetyGallery]}>
              <SafetyOverview />
            </Route>
            <Route
              exact
              path={[
                PATH_STRINGS.safetyMetricDetail,
                PATH_STRINGS.safetyMetricDetailFloor,
                PATH_STRINGS.safetyMetricDetailFloorPoint,
              ]}>
              <SafetyMetricDetail />
            </Route>
          </Switch>
        </SafetyContextProvider>
      </PannellumViewersProvider>
    </RealtimePositionProvider>
  </SafetyContextProvider>
);
